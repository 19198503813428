<template>
  <vs-card class="p-5">
    <label>Document Name</label>
    <vs-input v-model="documentName" class="mb-5 w-4/12" style="width: 100%" id="documentName" name="documentName" data-vv-validate-on="blur"
      data-vv-as="document name" v-validate="'required'"/>

    <span class="text-danger text-sm mt-3 mb-6 block" v-show="errors.has('documentName')">{{ errors.first("documentName") }}</span>

    <h3 class="text-base">Explicit Terms</h3>
    <vs-divider class="mt-2 mb-4"></vs-divider>
    <p class="text-sm mb-5"> This content will appear explicitly on the payment page - customers will need to read and accept these terms before completing payment.</p>

    <p>Available linked content</p>

    <div class="available-content-grid">
      <div class="available-content cursor-pointer" @click="addText('{{privacy}}', 'explicitTermsEditor')">Privacy</div>
      <div class="available-content cursor-pointer" @click="addText('{{fullTerms}}', 'explicitTermsEditor')">Full terms & conditions</div>
      <div class="available-content cursor-pointer" @click="addText('{{generalWebsiteTerms}}', 'explicitTermsEditor')">General website terms</div>
    </div>

    <editor v-model="explicitTerms" id="explicitTerms" name="explicitTerms" data-vv-validate-on="blur" data-vv-as="explicit terms" v-validate="'required'"
      ref="explicitTermsEditor"></editor>

    <span class="text-danger text-sm mt-3 mb-6 block" v-show="errors.has('explicitTerms')">{{ errors.first("explicitTerms") }}</span>

    <h3 class="text-base mt-10">Full Document</h3>
    <vs-divider class="mt-2 mb-4"></vs-divider>
    <p class="text-sm mb-5">A link to this content will appear payment page.</p>
    <p>Available linked content</p>
    <div class="available-content-grid">
      <div class="available-content cursor-pointer" @click="addText('{{privacy}}', 'fullDocumentEditor')">Privacy</div>
      <div class="available-content cursor-pointer" @click="addText('{{generalWebsiteTerms}}', 'fullDocumentEditor')">General website terms</div>
    </div>
    <editor v-model="fullDocument" id="fullDocument" name="fullDocument" data-vv-validate-on="blur" data-vv-as="full document" v-validate="'required'"
      ref="fullDocumentEditor"></editor>
    <span class="text-danger text-sm mt-3 mb-6 block" v-show="errors.has('fullDocument')">{{ errors.first("fullDocument") }}</span>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsList="errors" form-name="ProductLegalEditorForm"></pop-up>

    <div class="flex justify-end items-center">
      <vs-button class="w-full sm:w-auto mt-8" size="large" @click="upsertLegal()" :disabled="!validateForm" v-round>
        {{ this.$route.query.newVersion ? "Save new version" : "Create" }}
      </vs-button>

      <a @click="cancel()" style="margin-top: 2em" class="w-full sm:w-auto ml-5 underline">Cancel</a>
    </div>
  </vs-card>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapGetters, mapState } from "vuex";
import Editor from "../../../QuillEditor";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import _ from "lodash";

export default {
  components: {
    LeavePopup,
    PopUp,
    Editor,
    ValidationProvider,
  },
  data() {
    return {
      isButtonHidden: false,
      title: {
        PAY_NOW_TC: "Pay now T&Cs",
        PAY_LATER_TC: "Pay later T&Cs",
        PRIVACY_POLICY_TC: "Privacy policy",
      },
      popupActive: false,
      isSaved: false,
      nextObj: "", // determines the next route,
      explicitTerms: "",
      fullDocument: "",
      documentName: "",
      legalWithType: [],
    };
  },
  methods: {
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          this.upsertLegal();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },

    cancel() {
      this.$router.go(-1);
    },
    addText(val, editorRef) {
      this.$refs[editorRef].insertTextAtCursor(val);
    },

    upsertLegal() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const legal = {
            documentName: this.documentName,
            explicitTerms: this.explicitTerms,
            fullDocument: this.fullDocument,
            type: "PAY_NOW_TC",
            nature: "CARD",
            user: this.$route.query.user,
          };

          // increase version by 1 or set initial to 1
          legal.version =
            this.legalWithType.length === 0
              ? 1
              : Math.max(...this.legalWithType.map((o) => o.version), 0) + 1;

          this.$vs.loading();
          this.$store
            .dispatch("legals/insertLegal", legal)
            .then((result) => {
              this.$vs.loading.close();
              this.isSaved = true;
              this.$vs.notify({
                title: "Successful",
                text: "Document has been updated.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$vs.loading.close();

            });
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  beforeMount() {
    this.legalWithType = this.legals.filter(
      (el) =>
        el.type == "PAY_NOW_TC" &&
        el.nature == "CARD" &&
        el.user === this.$route.query.user
    );
    if (this.$route.query.newVersion) {
      const currentVersion = _.sortBy(this.legalWithType, ["createdAt"]).reverse()[0];
      this.documentName = currentVersion.documentName;
      this.fullDocument = currentVersion.fullDocument;
      this.explicitTerms = currentVersion.explicitTerms;
    }
  },
  computed: {
    ...mapState("legals", ["legals"]),
    validateForm() {
      return !this.errors.any();
    },
  },
};
</script>

